<template>
  <svg height="12" width="12">
    <circle cx="6" cy="6" r="6"  :fill="fillColor" />
  </svg>
</template>

<script>

import { COLORS } from '@/variables'

export default {
  name: "WolfStatus",
  props: ["status"],
  computed: {
      fillColor() {
        if(this.status == 1) {
          return COLORS.blue
        }
        if(this.status == 2) {
          return COLORS.red
        }
        if(this.status == 3) {
          return COLORS.yellow
        }
        return "000"
      }
    },
}
</script>

<style scoped>

</style>