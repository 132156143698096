<template>
  <div class="flex justify-between md:block items-center md:max-w-[260px]">
    <div class="md:mb-4 flex items-left">
      <div class="flex items-center mr-2">
        <wolf-status :status="1"></wolf-status>
        <div class="ml-2" v-html="texts.pack"></div>
      </div>
      <div class="flex items-center mr-2">
        <wolf-status :status="2"></wolf-status>
        <div class="ml-2" v-html="texts.couple"></div>
      </div>
      <div class="flex items-center">
        <wolf-status :status="3"></wolf-status>
        <div class="ml-2" v-html="texts.individual"></div>
      </div>
    </div>
    <div class="hidden md:block my-2 mx-2">
                <img class="max-h-24 w-auto" src="/logos/benelux_duitsland_v2.png" alt="">
<!--      <div class="flex items-center">-->
<!--        <div class="h-12 w-1/2 p-1 flex items-center justify-center">-->
<!--          <img class="max-h-12 w-auto" src="/logos/Vlaanderen_verbeelding werkt.png" alt="">-->
<!--        </div>-->
<!--        <div class="h-12 w-1/2 p-1 flex items-center justify-center">-->
<!--          <img class="max-h-12 w-auto" src="/logos/120413_IPO_BIJ12_Logo_Wide_RGB.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flex items-center">-->


<!--        <div class="h-12 w-1/2 p-1 flex items-center justify-center">-->
<!--          <img class="max-h-12 w-auto" src="/logos/DBBW_logo.png" alt="">-->
<!--        </div>-->
<!--        <div class="h-12 w-1/2 p-1 flex items-center justify-center">-->
<!--          <img class="max-h-12 w-auto" src="/logos/wallonie_v.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flex items-center justify-center">-->
<!--        <div class="h-12 w-1/2 p-1 flex items-center justify-center">-->
<!--          <img class="max-h-12 w-auto" src="/logos/ANF_LOGO.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
    </div>


    <div
        class="text-blue hover:text-red cursor-pointer md:mt-2"
        @click="$emit('show-info')">
       <i class="icon icon-info"></i> {{ texts.more_info }}
    </div>
  </div>
</template>

<script>

import WolfStatus from "@/components/WolfStatus";

export default {
  name: "WolfLegend",
  props:['texts'],
  components: {
    WolfStatus
  }
}
</script>

<style scoped>

</style>