<template>
  <div>

    <div class="shadow overflow-scroll rounded border border-brown"  style="max-height: 60vh">
    <table class="text-xs w-full">
      <thead class="bg-brown text-white">
      <tr>
        <table-header
            class="hidden md:table-cell"
        >{{ texts.Country }}</table-header>
        <table-header>{{ texts.Region }}</table-header>
        <table-header>{{ texts.Territory }}</table-header>
        <table-header>{{ texts.Status }}</table-header>
        <table-header
            class="text-overflow-ellipsis max-w-[40px] md:max-w-none truncate"
        >{{ texts.Reproduction }}</table-header>
        <table-header
            class="hidden md:table-cell"
        >{{ texts.Pups }}</table-header>
        <table-header
            class="hidden md:table-cell"
        >{{ texts.Female }}</table-header>
        <table-header
            class="hidden md:table-cell"
        >{{ texts.Male }}</table-header>
        <table-header>{{ texts.Year }}</table-header>
      </tr>
      </thead>
      <tbody>
      <wolf-row
          :texts="texts"
          @select-region="selectRegion"
          @select-territorium="selectTerritorium"
          :key="feature.properties.IDA"
          :feature="feature"
          :region="region"
          :territorium="territorium"
          :year-names="yearNames"
          v-for="feature in features">
      </wolf-row>
      </tbody>

    </table>
    </div>
  </div>
</template>

<script>

import WolfRow from "@/components/WolfRow";
import TableHeader from "@/components/TableHeader";
export default {
  name: "WolfObservations",
  props: ['features', 'region', 'territorium', 'texts', 'yearNames'],
  components: {
    TableHeader,
    WolfRow
  },
  methods: {
    selectRegion(region) {
      this.$emit('select-region', region)
    },
    selectTerritorium(territorium) {
      this.$emit('select-territorium', territorium)
    },
  }
}
</script>

<style scoped>

</style>