<template>
  <div class="box-border text-xs">
    <div class="border-b border-brown text-base py-1">
      {{ feature.properties.Region }}
    </div>
    <div class="border-b border-brown flex items-center justify-between py-1">
      <div class="pr-1">{{ feature.properties.Territorium }}</div>
      <div class="pl-1">{{ yearNames[feature.properties.Mjahr] }}</div>
    </div>
    <div v-if="feature.properties.Faehe || feature.properties.Ruede"
         class="flex border-brown border-b">
      <div class="w-1/2 border-r border-brown p-1">
        <div class="flex items-center pr-1" v-if="feature.properties.Faehe">
          <div><i class="icon icon-female"></i></div>
          <div class="pl-0.5">{{ texts.Female }}</div>
        </div>
        <div>{{ feature.properties.Faehe }}</div>
        <div>{{ feature.properties.FaeheHerkunft }}</div>
      </div>
      <div class="w-1/2 border-brown p-1">
        <div class="flex items-center" v-if="feature.properties.Ruede">
          <div><i class="icon icon-male"></i></div>
          <div class="pl-0.5">{{ texts.Male }}</div>
        </div>
        <div>{{ feature.properties.Ruede }}</div>
        <div>{{ feature.properties.RuedeHerkunft }}</div>
      </div>
    </div>
    <div class="flex items-center py-1">
      <wolf-status :status="feature.properties.Status"></wolf-status>
      <div class="pl-2">{{ getStatusName(feature.properties.Status) }}</div>
      <div v-if="feature.properties.Repro && feature.properties.Welpen">
        , {{ feature.properties.Welpen }} {{ texts.pups }}
      </div>

    </div>

  </div>

</template>

<script>

import WolfStatus from "@/components/WolfStatus";

export default {
  name: "PopupComponent",
  props: ["feature", 'yearNames', 'texts'],
  components: {
    WolfStatus
  },
  methods: {
    getStatusName(status) {
      if (status == 1) {
        return this.texts.pack
      }
      if (status == 2) {
        return this.texts.couple
      }
      if (status == 3) {
        return this.texts.individual
      }
    }
  }
}
</script>

<style scoped>

</style>