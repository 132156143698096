<template>
<div
class="hidden md:flex justify-between items-center bg-lightbrown  text-xs lg:text-sm font-semibold min-h-[36px]"
>
  <div class="flex px-2">
    <div
        v-for="year in years"
        :key="year"
        v-html="yearNames[year]"
        @click="selectYear(year)"
        class="transition-all duration-300  py-2 px-0.5 md:px-1 lg:px-2 cursor-pointer hover:text-white hover:bg-blue"
        :class="{'bg-blue': activeYear == year,'text-white': activeYear == year }"
    >
    </div>
    <div
        class="flex items-center ml-2 px-2 text-xl cursor-pointer hover:text-white hover:text-blue max-h-8"
        @click.prevent="animate">
      <i :class="[animating ? 'icon-pause': 'icon-play']" class="icon"></i>
      <!--    <span-->
      <!--        v-html="animating ?  texts.Stop_animation: texts.Animate_years"-->
      <!--    >-->
      <!--    </span>-->
    </div>
  </div>
<div class="flex">
  <div
      class="flex items-center px-2  cursor-pointer hover:text-white hover:text-blue max-h-8"
      @click.prevent="$emit('restart')">
    <i  class="text-xl icon icon-loop pr-2"></i>
    <span v-html="texts.Restart"></span>
  </div>
</div>



</div>
</template>

<script>
export default {
  name: "WolfYears",
  props: ['activeYear', 'years', 'yearNames','animating', 'texts'],
  data: function() {
    return {
      interval: undefined,
      index:0
    }
  },
  watch: {
    animating(animating) {
      if(!animating) {
        this.stopAnimating();
      }
    },
  },
  methods: {
    animate() {
      if(this.animating) {
        this.stopAnimating();
      } else {
        this.startAnimating();
      }
    },
    selectYear(year) {
      this.stopAnimating();
      this.$emit('select-year', year)
    },
    nextyear() {
      if(this.index >= this.years.length || !this.index) {
        this.index = 0;
      }
      let year = this.years[this.index];
      this.$emit('select-year', year);
      this.index++;
    },
    stopAnimating() {
      this.$emit('stop-animating');
      this.index =0;
      clearInterval(this.interval);
    },
    startAnimating() {
      this.$emit('start-animating');
      this.nextyear();
      this.interval = setInterval(() => this.nextyear(), 1000);
    }
  }
}
</script>

<style scoped>

</style>