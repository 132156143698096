<template>

  <tr class="bg-white even:bg-lightbrown">
    <table-data
    class="hidden md:table-cell">
      {{feature.properties.Country}}
    </table-data>
    <table-data>
      <div
          v-if="territorium"
          class="text-blue cursor-pointer hover:text-red"
          @click.prevent="$emit('select-region', feature.properties.Region)"
      >{{feature.properties.Region}}</div>
      <div v-else>{{feature.properties.Region}}</div>
    </table-data>

    <table-data>
      <div
          v-if="!territorium"
          class="text-blue cursor-pointer hover:text-red"
        @click.prevent="$emit('select-territorium', feature.properties.Territorium)">
      {{feature.properties.Territorium}}
    </div>
      <div v-else>
        {{feature.properties.Territorium}}
      </div>
    </table-data>
    <table-data
    >
      <wolf-status
      :status="feature.properties.Status">

      </wolf-status>

       </table-data>
    <table-data
    >{{feature.properties.Status !== 3 && feature.properties.Repro ? texts.Yes : texts.No}}</table-data>
    <table-data
        class="hidden md:table-cell"
    >{{ feature.properties.Status !== 3 ? feature.properties.Welpen : ''}}</table-data>
    <table-data
        class="hidden md:table-cell"
    >{{feature.properties.Faehe}}</table-data>
    <table-data
        class="hidden md:table-cell"
    >{{feature.properties.Ruede}}</table-data>
    <table-data
    >{{yearNames[feature.properties.Mjahr]}}</table-data>

  </tr>

</template>

<script>



import TableData from "@/components/TableData";
import WolfStatus from "@/components/WolfStatus";

export default {
  name: "WolfRow",
  props: ["feature", 'region', 'territorium', 'texts', 'yearNames'],
  components: {
    TableData,
    WolfStatus
  },

}
</script>

<style scoped>

</style>