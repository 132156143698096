<template>
  <div  class="mb-2 text-right">
    <select class="bg-white border border-bordercolor border-2 font-semibold rounded p-1.5" v-model="selectedLocale">
      <option
          :value="locale.code"
          :key="locale.code"
          v-for="locale in locales">
            {{ locale.name}}
      </option>
    </select>
  </div>

</template>

<script>
export default {
  name: "LanguageSwitcher",
  props: ["locales", 'locale'],
  watch: {
   selectedLocale(selectedLocale) {
     this.$emit('set-locale', selectedLocale);
     const urlParams = new URLSearchParams(window.location.search);
     urlParams.set('locale', selectedLocale);
     history.replaceState(null, null, "?" + urlParams.toString());
    }
  },
  data: function() {
    return {
      selectedLocale: undefined,
    }
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('locale')) {
      this.selectedLocale = urlParams.get('locale');
    } else {
      this.selectedLocale = this.locale;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>