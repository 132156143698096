<template>
  <td class="text-left p-1 border-r border-brown last:border-0">
    <slot></slot>
  </td>

</template>

<script>
export default {
  name: "TableData"
}
</script>

<style scoped>

</style>