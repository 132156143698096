<template>
  <div id="app" style="width: 100%; height: 100%;">
    <wolf-map></wolf-map>
  </div>
</template>

<script>
import WolfMap from './components/WolfMap.vue'

export default {
  name: 'App',
  components: {
    WolfMap
  }
}
</script>

<style>
html, body {
  width:100%;
  height: 100%;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
