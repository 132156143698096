<template>
  <div
      class="bg-white p-2 md:m-2 rounded border-2 border-bordercolor text-xs"
  >
    <slot></slot>
  </div>


</template>

<script>
export default {
  name: "WolfCard"
}
</script>

<style scoped>

</style>