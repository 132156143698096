<template>
  <div>
    <div class="relative">
      <div class="absolute top-0 right-0">
        <i
          @click="$emit('hide-details')"
          class="icon icon-cross cursor-pointer text-lightgrey"></i></div>
      <span v-if="features && packs"> {{packs}} {{packs > 1 ? texts.packs : texts.pack}}</span>
      <template v-if="packs && (couples || individuals)">, </template>
      <span  v-if="features && couples">{{couples}} {{couples > 1 ? texts.couples : texts.couple }}</span>
      <template v-if="couples && individuals">, </template>
      <span v-if="features && individuals">{{individuals}} {{individuals > 1 ? texts.individuals : texts.individual}}</span>
    </div>



    <div v-if="features && reproduction"> {{texts.reproduction_took_place_in}} {{reproduction}} {{ texts.packs }} {{ texts.with_a_total_of }} {{pups}} {{ texts.pups }}.</div>

    <div v-if="features && regions && regions.length > 1">
      <div class="pt-2">{{texts.territorial_wolves_regions}}:</div>
      <div>
                <span
                    class="text-blue hover:text-red cursor-pointer"
                    :key="region"
                    v-for="(region, index) in regions"
                    @click="$emit('select-region', region)"
                    v-html="index == regions.length-1 ? region :  region +', '"
                ></span>
      </div>



    </div>

  </div>

</template>

<script>
export default {
  name: "WolfDetails",
  props: ['year', 'yearNames', 'features', 'region', 'texts'],
  computed: {
    packs() {
      return this.features.filter((feature) => feature.properties.Status == 1).length;
    },
    couples() {
      return this.features.filter((feature) => feature.properties.Status == 2).length;
    },
    individuals() {
      return this.features.filter((feature) => feature.properties.Status == 3).length;
    },
    reproduction() {
      return this.features.filter((feature) => feature.properties.Repro == 1).length;
    },
    pups() {
      return this.features.reduce((count, feature) => (count + feature.properties.Welpen), 0);
    },
    regions() {
      return this.features.reduce((array, feature) => {
        if(array.indexOf(feature.properties.Region) == -1) {
          array.push(feature.properties.Region);
        }
        return array;
      },[]).sort();
    }
  }
}
</script>

<style scoped>

</style>