
const YEARS = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022
]
const YEARNAMES = {
  2010: '2010/11',
  2011: '2011/12',
  2012: '2012/13',
  2013: '2013/14',
  2014: '2014/15',
  2015: '2015/16',
  2016: '2016/17',
  2017: '2017/18',
  2018: '2018/19',
  2019: '2019/20',
  2020: '2020/21',
  2021: '2021/22',
  2022: '2022/23'
}

const YELLOW = '#E3AA36';
const RED = '#db5527'
const BLUE = '#3f7fbf'

const COLORS = {
  yellow:YELLOW,
  red:RED,
  blue: BLUE
}

export { YEARS, YEARNAMES, COLORS };