<template>
  <th class="text-left p-1 uppercase font-semibold text-xs">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "TableHeader"
}
</script>

<style scoped>

</style>