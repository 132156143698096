import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

Vue.config.productionTip = false


import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('VueMultiselect', Multiselect)

new Vue({
  render: h => h(App),
}).$mount('#app')

